import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Landing from "../components/contactSections/contact_landing"

import Layout from "../components/layout"

const ContactPage = () => (
  <Layout>
    <Landing />
  </Layout>
)

export default ContactPage
