import React from "react"

export default function ContactAddress({ address }) {
  return (
    <div className="contact-address">
      <div className="contact-address__line"></div>
      <div className="contact-address__content">
        {address.map((line, index) => {
          return (
            <p className="contact-address__text" key={index}>
              {line}
            </p>
          )
        })}
      </div>
    </div>
  )
}
