import React from "react"
import Rafiki from "../../assets/images/vectors/rafiki4.svg"
import { demoForm } from "../../constants"
// import ContactForm from "./contact-form"
import ContactAddress from "./contact-address"

const data = [
  [
    // "No. 1241, sector 18C",
    // "Chandigarh (U.T), 160018",
    // "+91 96503 12556",
    // "+91 99588 67718",
    "Main office:",
    "2402, Tower 14, Lotus Panache",
    "Noida Sector 110, Delhi NCR",
    "India",
    "+91 9958867718",
  ],

  [
    // "Up Tower",
    // "77 Sheikh Zayed Rd - Trade CentreDIFC",
    // " Dubai",
    // " United Arab Emirates",
    "Registered office:",
    "#1241, Sector 18-C",
    "Chandigarh",
    "India",
    "+91 9650312556",
  ],
]

export default () => {
  return (
    <div id="contact-landing" className="contact-landing">
      <div className="contact-landing__container">
        <div className="contact-landing__left">
          <h3 className="contact-landing__title">Contact Us</h3>

          <div className="u-margin-top-large"></div>

          <h3 className="address-title">Address</h3>
          <div className="contact-landing__address-container">
            {data.map((address, key) => {
              return <ContactAddress key={key} address={address} />
            })}
          </div>
          <div className="u-margin-top-medium"></div>

          <h3 className="contact-landing__title">
            Write to us at{" "}
            <a href="mailto:admin@webnyay.in">admin@webnyay.in </a>
          </h3>

          <div className="u-margin-top-large"></div>
          <h2 className="contact-landing__title">
            Want to know more about what we do?
          </h2>
          <a target="__blank" href={demoForm}>
            <p className="contact-landing__link">Request a demo</p>
          </a>
        </div>
        <div className="grow"></div>

        <div className="contact-landing__right">
          <img
            style={{ width: "45rem" }}
            alt="contact-landing us section image"
            src={Rafiki}
            alt=""
            data-sal="slide-right"
            data-sal-delay="200"
            data-sal-duration="700"
          />
        </div>
      </div>
    </div>
  )
}
